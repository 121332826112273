import { differenceInMinutes } from "date-fns";
import { useRouter } from "next/router";
import { graphql, useFragment } from "react-relay";
import type {
  EventCardProfessionalData,
  EventCardType,
} from "@olivahealth/oli-ui";
import PaymentStatus from "@olivahealth/graphql-server/src/domain/value-objects/PaymentStatus";
import SessionStatus from "@olivahealth/graphql-server/src/domain/value-objects/SessionStatus";
import WorkshopType from "@olivahealth/graphql-server/src/domain/value-objects/WorkshopType";
import { isActive } from "@olivahealth/utils/helpers/appointment";
import { getUserFullName } from "@olivahealth/utils/helpers/user";
import CareType from "@olivahealth/graphql-server/src/domain/value-objects/CareType";
import { formatShortDateTime } from "../../../utils/date/format";
import { OlivaHook } from "../../../hooks/OlivaHook";
import useTranslation from "../../../hooks/useTranslation";
import {
  useSessionCard_session$data as FragmentData,
  useSessionCard_session$key as Fragment,
} from "./__generated__/useSessionCard_session.graphql";

const useSessionCardFragment = graphql`
  fragment useSessionCard_session on SessionResponse {
    __typename
    ... on Session {
      id
      type
      number
      checkoutUrl
      hasPendingCheckInSurvey
      status
      endDate
      startDate
      careType
      professional {
        name
        surname
        clinicalProfile {
          image
          professionalTitle
        }
      }
      employee {
        name
        surname
      }
      therapy {
        careType
      }
      paymentStatus
    }
    ... on GroupSession {
      id
      status
      endDate
      startDate
      durationInMinutes
      hosts {
        name
        surname
      }
      workshop {
        private
        illustrationCard
        illustration
        title
        type
        slug
      }
      participants {
        id
      }
    }
  }
`;

interface UseSessionCardData {
  applyBackgroundMask: boolean;
  buttonLabel: string;
  careType: CareType | null;
  duration?: number;
  eventDate: string;
  eventType: EventCardType;
  eventTypeTranslation: string;
  imageSource: string | undefined;
  isAwaitingPayment: boolean;
  isRefunded: boolean;
  isSessionActive: boolean;
  pendingProgressCheckSurvey: boolean;
  professional?: EventCardProfessionalData;
  sessionId: string;
  title: string;
}

interface UseSessionCardEffects {
  handleClickButton: () => void;
}

type UseSessionCard = OlivaHook<UseSessionCardData, UseSessionCardEffects>;

export default function useSessionCard(session: Fragment): UseSessionCard {
  const { t } = useTranslation("employeeOneToOnePage", {
    keyPrefix: "yourSessions",
  });

  const data = useFragment<Fragment>(useSessionCardFragment, session);
  const { push } = useRouter();

  const is1To1Session = data.__typename === "Session";
  const isGroupSession = data.__typename === "GroupSession";
  const isManagerWorkshopSession =
    data.__typename === "GroupSession" &&
    data.workshop?.type === "MANAGER_WORKSHOP";
  const isTeamSession =
    data.__typename === "GroupSession" &&
    data.workshop?.type === "WORKSHOP_ONLY";
  const isSession = is1To1Session || isGroupSession;
  const isSessionActive = isSession && isActive(data.startDate);

  const duration = getEventDuration(data);
  const eventDate = isSession ? formatShortDateTime(data.startDate) : "";
  const eventType = getEventType(data);
  const professional = getProfessionalInfo(data);
  const title = getWorkshopTitle(data);
  const isAwaitingPayment =
    data.__typename === "Session" &&
    data.status === SessionStatus.AWAITING_PAYMENT &&
    data.checkoutUrl != null;
  const isProfessionalCoachingSession =
    is1To1Session && data.careType === "CAREER_COACHING";

  const isRefunded =
    data.__typename === "Session" &&
    data.paymentStatus === PaymentStatus.REFUNDED;

  const handleClickButton = () => {
    if (
      isSession &&
      isSessionActive &&
      !isManagerWorkshopSession &&
      !isTeamSession
    ) {
      push(`/sessions/${data.id}/talk`);
    } else if (isAwaitingPayment) {
      push(data.checkoutUrl);
    } else if (isTeamSession) {
      // TODO manage a new page for team sessions as employee
      push(`/organisation/workshops/${data.workshop.slug}`);
    } else if (isManagerWorkshopSession) {
      push(`/organisation/workshops/${data.workshop.slug}`);
    } else if (isSession) {
      push(`/sessions/${data.id}`);
    }
  };

  const getButtonLabel = () => {
    if (isAwaitingPayment) {
      return t("sessionCard.buttonLabelPayNow");
    }

    if (is1To1Session) {
      return isSessionActive
        ? t("sessionCard.buttonLabelJoinCall")
        : t("sessionCard.buttonLabelGoToSessionPage");
    }

    if (isGroupSession) {
      return isSessionActive
        ? t("sessionCard.buttonLabelJoinWorkshop")
        : t("sessionCard.buttonLabelViewDetails");
    }

    return t("sessionCard.buttonLabelViewDetails");
  };

  const pendingProgressCheckSurvey =
    data.__typename === "Session" &&
    data.hasPendingCheckInSurvey &&
    ["ACTIVE", "SCHEDULED"].includes(data.status);

  return {
    status: "success",
    data: {
      applyBackgroundMask:
        isGroupSession && !isManagerWorkshopSession && !isTeamSession,
      buttonLabel: getButtonLabel(),
      careType: data.__typename === "Session" ? (data.careType ?? null) : null,
      duration,
      eventDate,
      eventType,
      eventTypeTranslation: isProfessionalCoachingSession
        ? t("sessionCard.eventType.professionalCoaching")
        : t(`sessionCard.eventType.${eventType}`),
      imageSource: getEventImageSource(data, isSessionActive),
      isAwaitingPayment,
      isRefunded,
      isSessionActive,
      pendingProgressCheckSurvey,
      professional,
      sessionId: isSession ? data.id : "",
      title,
    },
    effects: {
      handleClickButton,
    },
    ...(data.__typename === "%other" && {
      error: { reason: "[useSessionCard] Error fetching session" },
    }),
  };
}

function getEventType(data: FragmentData): EventCardType {
  if (data.__typename === "Session") {
    if (data.type === "DROP_IN") {
      return "check-in";
    }

    return "therapy";
  }

  if (data.__typename === "GroupSession") {
    if (data.workshop?.type === WorkshopType.WORKSHOP_ONLY) {
      return "teamSession";
    }
    if (data.workshop?.private) {
      return "workshop";
    }
  }

  return "class";
}

function getEventImageSource(data: FragmentData, isActive: boolean) {
  switch (data.__typename) {
    case "GroupSession":
      return (
        (isActive
          ? data.workshop?.illustration
          : data.workshop?.illustrationCard) ||
        `/img/img_eventcard_${isActive ? "active" : "default"}.jpg`
      );
  }
}

function getProfessionalInfo(
  data: FragmentData,
): EventCardProfessionalData | undefined {
  if (data.__typename !== "Session") {
    return undefined;
  }

  return {
    name: getUserFullName(data.professional),
    image: data.professional?.clinicalProfile?.image ?? null,
    title: data.professional?.clinicalProfile?.professionalTitle ?? null,
  };
}

function getWorkshopTitle(data: FragmentData): string {
  if (data.__typename === "GroupSession") {
    return data.workshop?.title ?? "";
  }

  return "";
}

function getEventDuration(data: FragmentData): number | undefined {
  if (data.__typename === "GroupSession" && data.durationInMinutes) {
    return data.durationInMinutes;
  } else if (
    data.__typename === "GroupSession" ||
    data.__typename === "Session"
  ) {
    return differenceInMinutes(
      new Date(data.endDate),
      new Date(data.startDate),
    );
  }

  return undefined;
}
